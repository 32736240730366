import React, { FC, ReactElement } from 'react';

interface IProps {
  title: string;
  isHeadlineTitle?: boolean;
  classes?: string;
  border?: ReactElement;
}

const Headline: FC<IProps> = ({
  title,
  isHeadlineTitle = true,
  classes,
  border
}: IProps) => (
  <div className={`row ${classes} ms-0 me-0 gx-0`}>
    <div className="col-md-12">
      {isHeadlineTitle ? (
        <h1 className="h1-mod3">{title}</h1>
      ) : (
        <p className="h1-mod3 font-color-default jost-extra-bold d-block mb-2">
          {title}
        </p>
      )}
    </div>
    {border && border}
  </div>
);

export default Headline;
