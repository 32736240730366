import Config from '../config';

export const ApiUrls = {
  SEARCH_ITEMS: 'pc-frontend-api/search/quick-search-products',
  FETCH_ADVANCED_SEARCH_ITEMS: 'pc-frontend-api/search/advance-search',
  USER_LOGIN: 'auth/login',
  USER_LOGOUT: 'auth/logout',
  USER_CREATE: '/users/create',
  USER_CONFIRM_REGISTRATION: '/users/confirm-registration',
  NEWSLETTER_CONFIRM: '/newsletter/confirm-subscribe',
  NEWSLETTER_UNSUBSCRIBE: '/newsletter/unsubscribe',
  NEWSLETTER_CONFIRM_UNSUBSCRIBE: '/newsletter/confirm-unsubscribe',
  TEXT_SLIDERS: 'text-slider',
  HOMEPAGE: 'pc-frontend-api/homepage',
  PRODUCT_PAGE: 'pc-frontend-api/overview',
  PRODUCT_BY_NAV_ID: 'product/getByNavId',
  PC_FRONTEND_API: 'pc-frontend-api',
  HOMEPAGE_TOP_CAROUSEL: '/carousel',
  BOOKSTORE_PAGE: '/bookstores',
  FETCH_BOOKSTORE_BY_OLD_ID: '/bookstores/getByOld',
  FETCH_AVAILABLE_BOOKSTORES: '/bookstores/product',
  FETCH_PROFILE_USER: '/users/profile',
  DELETE_PROFILE_USER: '/users/remove',
  FETCH_PROFILE_ORDER_HISTORY: '/checkout/order',
  FETCH_PROFILE_ACTIVITY: '/comment/user',
  PROFILE_USER_UPDATE: '/users/update',
  PROFILE_USER_PASSWORD_CHANGE: '/password/change',
  ADD_COMMENT: '/comment',
  GET_ADMINISTRATIVE_BAN: 'pc-frontend-api/administrative-ban',
  GET_LAST_NEWS: '/news',
  GET_NEWS_SECTIONS: '/pc-frontend-api/news-page',
  GET_COUNTRIES: '/countries',
  GET_MUNICIPALITIES: '/municipalities',
  WISHLIST: '/users/get-wishlist-state',
  GET_CART_ITEMS: 'checkout/cart',
  GET_LAST_VIEWED_PRODUCTS: 'pc-frontend-api/getLastViewedProducts',
  GET_WSPAY_REDIRECT_PARAMS: Config.api.wspayRediretDataUrl,
  ASK_ABOUT_PRODUCT: 'pc-frontend-api/product/ask-about',
  GET_KIDS_BOOK: 'pc-frontend-api/kids-book',
  GET_FOREIGN_BOOK: 'pc-frontend-api/foreign-book',
  GET_SCHOOL_BOOK: 'pc-frontend-api/school-book',
  GET_STARWAY: 'pc-frontend-api/starway',
  GET_BOOK_PAGE_DATA: 'pc-frontend-api/book',
  GET_GAME_PAGE_DATA: 'pc-frontend-api/game',
  GET_GIFT_PAGE_DATA: 'pc-frontend-api/gift',
  GET_MUSIC_PAGE_DATA: 'pc-frontend-api/music',
  GET_ACTIONS_PAGE: 'pc-frontend-api/actions-page',
  GET_ACTIONS_LIST_PAGE: 'pc-frontend-api/actions-list',
  CREATE_ORDER: 'checkout/order',
  GET_BOOKMAKER_MAGAZINE_PAGE_DATA: 'pc-frontend-api/bookmakers-magazine',
  GET_CAREER_PAGE_DATA: 'pc-frontend-api/delfi-career',
  GET_FAQS: '/faq/all',
  GET_PRODUCT_LIST_PRODUCTS: 'pc-frontend-api/product-list',
  FETCH_FILTERS_DATA: 'pc-frontend-api/get-filters-data',
  NEWSLETTER_SUBSCRIBE: '/newsletter',
  GET_SECTION_PRODUCTS: 'pc-frontend-api/section',
  JOIN_TO_DELFI_PREMIUM: 'auth-users/join-delfi-premium',
  SEND_FORGOT_PASSWORD_EMAIL: 'password/email',
  SEND_RESET_USER_PASSWORD: 'password/reset',
  EDEN_BOOKS_CREATE_ORDER: 'checkout/order/ebook',
  EDEN_BOOKS_SEND_FORGOT_PASSWORD_EMAIL: 'password/eden-email',
  EDEN_BOOKS_RESET_USER_PASSWORD: 'password/eden-reset',
  GET_ORDER_STATUS: 'checkout/order',
  GET_STATIC_PAGE_DATA: 'static-page',
  SEND_COMPLAINT: 'pc-frontend-api/send-complaint',
  GET_MENU_ITEMS: 'menu-items/getItems',
  SUBMIT_AVAILABLE_NOTIFICATION_DATA: 'pc-frontend-api/available-again',
  GET_BOOKTOK_PAGE_DATA: 'pc-frontend-api/booktok',
  FETCH_AUTHOR_ID_BY_OLD_ID: 'pc-frontend-api/author/oldId',
  FETCH_GENRE_NAME_BY_OLD_ID: 'pc-frontend-api/genre/oldId',
  GET_PREMIUM_ACTIONS_PAGE_DATA: 'pc-frontend-api/premium-actions',
  GET_TOP_LISTS_PAGE_DATA: 'pc-frontend-api/toplists',
  GET_FILM_PAGE_DATA: 'pc-frontend-api/films',
  GET_BOARD_GAMES_PAGE_DATA: 'pc-frontend-api/board-games',
  GET_RUSSIAN_BOOKS_PAGE_DATA: 'pc-frontend-api/russian-books',
  GET_MANGE_PAGE_DATA: 'pc-frontend-api/mange',
  GET_MAGYAR_PAGE_DATA: 'pc-frontend-api/magyar',
  SAVE_TO_WISHLIST_REQUEST: 'users/set-wishlist-state',
  UPDATE_NOT_FOUND_URL: 'pc-frontend-api/not-found-url'
};

export default ApiUrls;
