import React, { FC } from 'react';
import AppRouter from './routes';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const tagManagerArgs = {
  gtmId: 'GTM-K3GRJMR'
};

const TRACKING_ID = 'G-0E9HNBLLPW';

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(TRACKING_ID);

const App: FC = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title> Delfi knjižare | Sve dobre knjige na jednom mestu</title>
          <meta property="og:title" content="Delfi knjižare" />
          <meta
            property="og:description"
            content="Delfi knjižare | Sve dobre knjige na jednom mestu"
          />
          <meta property="og:type" content="book" />
          <meta property="og:image:alt" content="Delfi knjižare" />
        </Helmet>
        <AppRouter />
      </HelmetProvider>
    </>
  );
};

export default App;
