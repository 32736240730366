import React, {
  FC,
  FormEvent,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import { AppState } from '../../../../app/store/rootReducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  fetchSearchItems,
  setInitSearchItems
} from '../../../../app/store/search-items/actions';
import {
  fetchProductByNavIdAction,
  resetProductByNavIdAction
} from '../../../../app/store/product-by-nav-id/actions';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp
} from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';
import './scss/style.scss';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ProductOfTheWeek from './product-of-the-week';
import NavIdRegex from '../../../../constants/NavIdRegex';
import Authors from '../../../components/authors';
import ProductByNavId from './product-by-nav-id';
import { sanitizeSearchParam } from '../../../../utilites/search-param';
import categoryMap from '../../../../utilites/categoryMap';

interface IProps {
  setIsShowSearchItems: (showSearchItems: boolean) => void;
  isShowSearchItems: boolean;
  setIsCategoryDropdownOpen?: (isCategoryDropdownOpen: boolean) => void;
  isCategoryDropdownOpen?: boolean;
}

type Props = IProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SearchBarContainer = forwardRef<any, Props>(
  (
    {
      searchItems,
      productByNavId,
      fetchSearchItems,
      fetchProductByNavIdAction,
      resetProductByNavIdAction,
      setInitSearchItems,
      setIsShowSearchItems,
      setIsCategoryDropdownOpen,
      isShowSearchItems,
      isCategoryDropdownOpen
    }: Props,
    ref: ForwardedRef<any>
  ) => {
    const location = useLocation();
    const [category, setCategory] = useState('Sve kategorije');
    const [searchParam, setSearchParam] = useState('');
    const MINIMUM_SEARCH_CHARS = 2;

    const {
      searchWrapperRef,
      searchCategoryDropdownRef
      // @ts-ignore
    } = ref.current;

    const inputSearchRef: MutableRefObject<any> = useRef(null);
    const navigate = useNavigate();

    const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1308px)' });
    const isLaptop = useMediaQuery({ query: '(max-width: 1600px)' });

    const navigateToSearchPage = () => {
      let cat = category;
      if (categoryMap.get('Sve kategorije') === cat) {
        cat = 'all';
      }
      navigate(`pretraga?q=${searchParam}&category=${cat}`);

      if (inputSearchRef && inputSearchRef.current) {
        inputSearchRef.current.value = '';
      }

      setIsShowSearchItems(false);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const value = formData.get('search-input') as string;

      if (value !== '' && value && value.length > MINIMUM_SEARCH_CHARS) {
        navigateToSearchPage();
        return e.currentTarget.reset();
      }
    };

    const handleOnChange = (searchParam: string) => {
      if (searchParam.length > MINIMUM_SEARCH_CHARS) {
        setIsShowSearchItems(true);
        if (NavIdRegex.test(searchParam)) {
          return fetchProductByNavIdAction(searchParam.toUpperCase());
        }

        resetProductByNavIdAction();
        searchParam = sanitizeSearchParam(searchParam);

        fetchSearchItems(category, searchParam.toLowerCase());
        return setSearchParam(searchParam);
      }
      setIsShowSearchItems(false);
      setInitSearchItems();
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pasteText: string = event.clipboardData.getData('text');

      if (pasteText.length > MINIMUM_SEARCH_CHARS) {
        setIsShowSearchItems(true);
        if (NavIdRegex.test(pasteText)) {
          return fetchProductByNavIdAction(pasteText.toUpperCase());
        }

        resetProductByNavIdAction();
        fetchSearchItems(category, pasteText.toLowerCase());
        return setSearchParam(pasteText);
      }

      setIsShowSearchItems(false);
      setInitSearchItems();
    };

    const checkRenderSearchItems = (): boolean =>
      searchItems.results.length > 0 && searchItems.totalNumberOfResults > 0;

    const handleOnFocusSearchItems = () => {
      if (searchParam.length > MINIMUM_SEARCH_CHARS) {
        return setIsShowSearchItems(true);
      }

      setIsShowSearchItems(false);
    };

    const handleOnClickCategory = (ctgory: string) => {
      setCategory(ctgory);
      if (inputSearchRef && inputSearchRef.current) {
        inputSearchRef.current.focus();
      }
      if (searchParam.length > MINIMUM_SEARCH_CHARS) {
        fetchSearchItems(ctgory, searchParam.toLowerCase());
        setIsShowSearchItems(true);
      }
    };

    const handleOnClickOpenDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (setIsCategoryDropdownOpen) {
        setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
      }
    };

    useEffect(() => {
      setIsShowSearchItems(false);
    }, [location]);

    return isMobile ? (
      <div className="col-md-12 position-relative">
        <form
          className="input-group text-14 jost-medium text-muted"
          onSubmit={handleSubmit}
        >
          <input
            ref={inputSearchRef}
            type="text"
            className="form-control rounded-0 shadow-none"
            name="search-input"
            placeholder="Pretražite proizvode..."
            onChange={(e) =>
              handleOnChange((e.target as HTMLInputElement).value)
            }
            onPaste={handlePaste}
            onFocus={handleOnFocusSearchItems}
          />
          <button
            className="input-group-text background-orange input"
            type="submit"
          >
            <AiOutlineSearch size="1.5em" />
          </button>
        </form>
        {isShowSearchItems ? (
          productByNavId.data ? (
            <div
              className="position-absolute search-bar-items-container search-bar-items-container-width"
              ref={searchWrapperRef}
            >
              <ProductByNavId
                product={productByNavId.data}
                setIsShowSearchItems={setIsShowSearchItems}
              />
            </div>
          ) : (
            <div
              className="position-absolute search-bar-items-container"
              ref={searchWrapperRef}
            >
              <div className="bg-white box-shadow-container">
                {checkRenderSearchItems() ? (
                  <div className="row mb-3 p-3">
                    {searchItems.results.map((product: any, index) => (
                      <Link
                        to={product.navigateTo}
                        onClick={() => setIsShowSearchItems(false)}
                        key={product.id}
                        className="text-decoration-none"
                      >
                        <div className="col-12 h-100">
                          <div
                            key={product.id}
                            className="row align-items-center mt-2 mb-2"
                          >
                            <div className="col-auto">
                              <img
                                src={product.images.m}
                                alt={product.title + '_img'}
                                className="float-left"
                                style={{
                                  height: '80px',
                                  width: '55px'
                                }}
                              />
                            </div>
                            <div className="col-auto overflow-hidden item-text-title">
                              <div className="jost-semi-bold text-16 line-height-20">
                                <p className="mb-0">{product.title}</p>
                                <div className="max-lines max-lines-2 max-height-40 mt-1">
                                  <Authors authors={product.authors} />
                                </div>
                              </div>
                            </div>
                            <div className="border-2 border-bottom mt-2 mb-2" />
                          </div>
                        </div>
                      </Link>
                    ))}
                    <div className="col-12 text-start">
                      <a
                        className="color-active-item jost-extra-bold text-12 text-decoration-none cursor-pointer"
                        onClick={navigateToSearchPage}
                      >
                        PREGLEDAJ SVE REZULTATE
                        {/*PREGLEDAJ SVE REZULTATE ({searchItems.totalNumberOfResults})*/}
                        <MdKeyboardArrowRight
                          size="1.1em"
                          className="ms-2 mb-1 color-active-item"
                        />
                      </a>
                    </div>
                    {searchItems.prominentProduct !== null ? (
                      <div className="col-12 text-start p-3 mb-4 mt-4">
                        <div>
                          <h6 className="text-uppercase text-14">
                            Istaknuti proizvod
                          </h6>
                        </div>
                        <ProductOfTheWeek
                          product={searchItems.prominentProduct}
                          setIsShowSearchItems={setIsShowSearchItems}
                          isMobile
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="col-md-12 p-3 text-start">
                    <span>Nema rezultata pretrage</span>
                  </div>
                )}
              </div>
            </div>
          )
        ) : null}
      </div>
    ) : (
      <div
        className={`${
          isLaptop ? 'p-2' : 'p-3'
        } col-6 col-md-8 col-lg-6 offset-lg-2 bg-white mt-2 position-relative`}
        ref={searchWrapperRef}
      >
        <form className="input-group" onSubmit={handleSubmit}>
          <button aria-label="pretraga" className="btn input shadow-none p-0">
            <AiOutlineSearch size="1.5em" />
          </button>
          <input
            ref={inputSearchRef}
            placeholder="Pretražite proizvode..."
            type="text"
            name="search-input"
            className="form-control inputRemoveBorder inputHeight shadow-none jost-regular text-16"
            aria-label="Text input with 2 dropdown buttons"
            onChange={(e) =>
              handleOnChange((e.target as HTMLInputElement).value)
            }
            onPaste={(e) => handlePaste(e)}
            onFocus={handleOnFocusSearchItems}
          />
          <div
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              handleOnClickOpenDropdown(e)
            }
            className="cursor-pointer"
          >
            <span className="me-2 text-16 jost-semi-bold text-color-blue">
              {categoryMap.get(category) || category}
            </span>
            {isCategoryDropdownOpen ? (
              <MdKeyboardArrowUp size="1em" className="color-active-item" />
            ) : (
              <MdKeyboardArrowDown size="1em" className="color-active-item" />
            )}
          </div>
        </form>
        {isCategoryDropdownOpen ? (
          <ul
            ref={searchCategoryDropdownRef}
            className={`bg-white footer-links category-items col-12 text-end jost-semi-bold text-16 cursor-pointer box-shadow-category-dropdown ${
              isTablet ? 'w-35' : 'w-25'
            }`}
          >
            <li>
              <span
                className={`dropdown-item ${
                  category === 'Sve kategorije' ? 'active' : ''
                }`}
                onClick={() => handleOnClickCategory('Sve kategorije')}
              >
                Sve kategorije
              </span>
            </li>
            <li>
              <span
                className={`dropdown-item ${
                  category === 'Knjiga' ? 'active' : ''
                }`}
                onClick={() => handleOnClickCategory('Knjiga')}
              >
                Knjige
              </span>
            </li>
            <li>
              <span
                className={`dropdown-item ${
                  category === 'Gift' ? 'active' : ''
                }`}
                onClick={() => handleOnClickCategory('Gift')}
              >
                Gift
              </span>
            </li>
            <li>
              <span
                className={`dropdown-item ${
                  category === 'Strana knjiga' ? 'active' : ''
                }`}
                onClick={() => handleOnClickCategory('Strana knjiga')}
              >
                Strane knjige
              </span>
            </li>
            <li>
              <span
                className={`dropdown-item ${
                  category === 'Film' ? 'active' : ''
                }`}
                onClick={() => handleOnClickCategory('Film')}
              >
                Film
              </span>
            </li>
            <li>
              <span
                className={`dropdown-item ${
                  category === 'Muzika' ? 'active' : ''
                }`}
                onClick={() => handleOnClickCategory('Muzika')}
              >
                Muzika
              </span>
            </li>
            {/*<li>*/}
            {/*  <span*/}
            {/*    className={`dropdown-item ${*/}
            {/*      category === ' Video Igra' ? 'active' : ''*/}
            {/*    }`}*/}
            {/*    onClick={() => handleOnClickCategory(' Video Igra')}*/}
            {/*  >*/}
            {/*    Video Igra*/}
            {/*  </span>*/}
            {/*</li>*/}
          </ul>
        ) : null}
        {isShowSearchItems ? (
          productByNavId.data ? (
            <div className="position-absolute search-bar-items-container search-bar-items-container-width">
              <ProductByNavId
                product={productByNavId.data}
                setIsShowSearchItems={setIsShowSearchItems}
              />
            </div>
          ) : (
            <div className="position-absolute search-bar-items-container search-bar-items-container-width">
              <div className="bg-white container box-shadow-container">
                <div
                  className={`row ${
                    isLaptop ? 'p-2' : 'justify-content-center p-3'
                  }`}
                >
                  {checkRenderSearchItems() ? (
                    <>
                      <div
                        className={`${
                          isTablet ? 'text-center col-4' : 'text-start col'
                        } jost-extra-bold text-14`}
                      >
                        <h6 className="text-uppercase text-14">
                          {categoryMap.get(category) || category}
                        </h6>
                      </div>
                      {searchItems.prominentProduct !== null && !isTablet ? (
                        <div
                          className={`${
                            isTablet ? 'col-4' : 'col'
                          } text-start ${isLaptop ? '' : 'mb-4'}`}
                        >
                          <h6 className="text-uppercase text-14">
                            Istaknuti proizvod
                          </h6>
                        </div>
                      ) : null}
                      <div className="row">
                        <div className="col">
                          {searchItems.results.map((product, index) => {
                            return (
                              <Link
                                to={product.navigateTo}
                                onClick={() => setIsShowSearchItems(false)}
                                className="text-decoration-none font-color-default"
                              >
                                <div className="row">
                                  <div className="d-flex flex-column h-100">
                                    <div
                                      key={product.id}
                                      className={`row align-items-center ${
                                        isLaptop ? 'pt-1' : 'pt-2'
                                      }`}
                                    >
                                      <div className="col-auto">
                                        <img
                                          src={product.images.s}
                                          alt={product.title + '_img'}
                                          className="float-left"
                                          style={{
                                            height: `${
                                              isLaptop ? '80px' : '95px'
                                            }`,
                                            width: `${
                                              isLaptop ? '60px' : '70px'
                                            }`
                                          }}
                                        />
                                      </div>
                                      <div className="col text-start overflow-hidden item-text-title">
                                        <div
                                          className={`jost-semi-bold ${
                                            isLaptop
                                              ? 'text-14 line-height-16'
                                              : 'text-16 line-height-20'
                                          }`}
                                        >
                                          <h2 className="h2-mod6 mb-0 max-lines max-lines-2">
                                            {product.title}
                                          </h2>
                                          <div className="max-lines max-lines-2 max-height-40 mt-1">
                                            <Authors
                                              authors={product.authors}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border-2 border-bottom mt-2 mb-2" />
                                </div>
                              </Link>
                            );
                          })}
                          <div
                            className={`row ${
                              isTablet ? 'text-center' : 'text-start'
                            }`}
                          >
                            <div
                              className={`col-12 ${
                                isTablet ? 'text-center' : 'text-start'
                              }`}
                            >
                              <a
                                className="color-active-item jost-extra-bold text-12 text-decoration-none cursor-pointer"
                                onClick={navigateToSearchPage}
                              >
                                PREGLEDAJ SVE REZULTATE
                                {/*REZULTATE ({searchItems.totalNumberOfResults})*/}
                                <MdKeyboardArrowRight
                                  size="1.1em"
                                  className="ms-2 mb-1 color-active-item"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        {searchItems.prominentProduct !== null ? (
                          <div
                            className={`col ${
                              isTablet ? 'text-center mt-4' : 'text-start'
                            }`}
                          >
                            {isTablet && (
                              <div>
                                <h6 className="text-uppercase text-14">
                                  Istaknuti proizvod
                                </h6>
                              </div>
                            )}
                            <ProductOfTheWeek
                              product={searchItems.prominentProduct}
                              setIsShowSearchItems={setIsShowSearchItems}
                            />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div className="col-md-12 p-3 text-start">
                      <span>Nema rezultata pretrage</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }
);

const mapStateToProps = (state: AppState) => ({
  searchItems: state.searchItems,
  productByNavId: state.productByNavId,
  ui: state.ui
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      fetchSearchItems,
      fetchProductByNavIdAction,
      resetProductByNavIdAction,
      setInitSearchItems
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(SearchBarContainer);
