import {
  IAction,
  IAttribute,
  IAuthor,
  ICharacteristic,
  IDisplayPrices,
  IGenre,
  IImage,
  IMetaData,
  IPriceList,
  IProduct,
  IProductGalleryItem,
  IRankList,
  ISticker,
  ITopComment
} from './types';

export default class Product implements IProduct {
  public id: string;

  public actions: IAction[];

  public images: IImage;

  public priceList: IPriceList;

  public displayPrices: IDisplayPrices;

  public displayStickers: ISticker[];

  public attributes: IAttribute[];

  public publisher: string | null;

  public authors: IAuthor[] | string;

  public ean: string | null;

  public quantity: string | null;

  public isLaguna: boolean;

  public genreAnalytics: string;

  public pgRating: string;

  public cover: string;

  public pdv: number | string;

  public order: string | null;

  public isNewProduct: boolean;

  public isFree: boolean;

  public status: boolean;

  public isAvailable: boolean;

  public isLiveLager: boolean;

  public shortDescription: string;

  public description: string;

  public title: string;

  public subgenre: any[];

  public category: string;

  public barcode: string;

  public navId: string;

  public alphabets: string[];

  public topComments: ITopComment[];

  public averageRank: number;

  public totalNumberOfComments: number;

  public rankList: IRankList;

  public authorLabel: string;

  public publisherLabel: string;

  public metaData: IMetaData;

  public eBook: boolean;

  public productType: string;

  public oldProductId: number;

  public characteristics: ICharacteristic[];

  public navigateTo: string;

  public releaseDate?: string;

  public genres?: IGenre[];

  public excerptPath?: string;

  public categoryUrl?: string;

  public isActionOnFly?: boolean;

  public imgGallery?: any;

  public metaTitle?: string;

  constructor(
    id: string,
    actions: IAction[],
    images: IImage,
    priceList: IPriceList,
    displayPrices: IDisplayPrices,
    displayStickers: ISticker[],
    attributes: IAttribute[],
    publisher: string | null,
    authors: IAuthor[] | string,
    ean: string | null,
    quantity: string | null,
    isLaguna: boolean,
    genreAnalytics: string,
    pgRating: string,
    cover: string,
    pdv: number | string,
    order: string | null,
    isNewProduct: boolean,
    isFree: boolean,
    status: boolean,
    isAvailable: boolean,
    isLiveLager: boolean,
    shortDescription: string,
    description: string,
    title: string,
    subgenre: any[],
    category: string,
    barcode: string,
    navId: string,
    alphabets: string[],
    topComments: ITopComment[],
    averageRank: number,
    totalNumberOfComments: number,
    rankList: IRankList,
    authorLabel: string,
    publisherLabel: string,
    metaData: IMetaData,
    eBook: boolean,
    productType: string,
    oldProductId: number,
    characteristics: ICharacteristic[],
    navigateTo: string,
    releaseDate?: string,
    genres?: IGenre[],
    excerptPath?: string,
    categoryUrl?: string,
    isActionOnFly?: boolean,
    imgGallery?: any,
    metaTitle?: string
  ) {
    this.id = id;
    this.actions = actions;
    this.images = images;
    this.priceList = priceList;
    this.displayPrices = displayPrices;
    this.displayStickers = displayStickers;
    this.attributes = attributes;
    this.publisher = publisher;
    this.authors = authors;
    this.ean = ean;
    this.quantity = quantity;
    this.isLaguna = isLaguna;
    this.genreAnalytics = genreAnalytics;
    this.pgRating = pgRating;
    this.cover = cover;
    this.pdv = pdv;
    this.order = order;
    this.isNewProduct = isNewProduct;
    this.isFree = isFree;
    this.status = status;
    this.isAvailable = isAvailable;
    this.isLiveLager = isLiveLager;
    this.shortDescription = shortDescription;
    this.description = description;
    this.title = title;
    this.subgenre = subgenre;
    this.category = category;
    this.barcode = barcode;
    this.navId = navId;
    this.alphabets = alphabets;
    this.topComments = topComments;
    this.averageRank = averageRank;
    this.totalNumberOfComments = totalNumberOfComments;
    this.rankList = rankList;
    this.authorLabel = authorLabel;
    this.publisherLabel = publisherLabel;
    this.metaData = metaData;
    this.eBook = eBook;
    this.productType = productType;
    this.oldProductId = oldProductId;
    this.characteristics = characteristics;
    this.navigateTo = navigateTo;
    this.releaseDate = releaseDate;
    this.genres = genres;
    this.excerptPath = excerptPath;
    this.categoryUrl = categoryUrl;
    this.isActionOnFly = isActionOnFly;
    this.imgGallery = imgGallery;
    this.metaTitle = metaTitle;
  }
}
